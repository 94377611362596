import React, { useEffect, useRef, useState } from "react";
import { Img, Heading, Text } from "./..";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "store/slices/UserSlice";
import { PRIVILEGEDEMAILS } from "../../config/config";
import { getCredits } from "../../api/auth";

export default function Header({ ...props }) {
  const [credits, setCredits] = useState(null);
  const { userObj } = useSelector((state) => state.user);
  const PAGES = [
    { title: "Google Maps", urls: ["/google-maps"] },
    { title: "Results", urls: ["/results"] },
  ];

  // const additionalPages = [
  // { title: "Jobs", urls: ["/jobs"] },
  // { title: "Email Validation", urls: ["/email-validation"] },
  // ]; // const privilegedEmails = [];

  // if (PRIVILEGEDEMAILS.includes(userObj.email)) {
  // PAGES.push(...additionalPages);
  // }

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleLogout = () => {
    dispatch(clearUser());
    navigate("/sign-in");
  };
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu visibility on click
  };
  const handleMenuItemClick = (item) => {
    if (item === "logout") handleLogout();
    else if (item === "settings") navigate("/settings");
    setIsMenuOpen(false);
  };
  const catMenu = useRef(null);
  const closeOpenMenus = (e) => {
    if (isMenuOpen && !catMenu.current?.contains(e.target)) {
      setIsMenuOpen(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const loadCredits = async () => {
    let { credits } = await getCredits(userObj?.token);
    if (credits && credits >= 0) {
      setCredits(credits);
    }
  };

  useEffect(() => {
    loadCredits();
  }, []);

  return (
    <header className="p-2 sm:p-0 bg-white-A700 rounded-[16px]">
      <div
        className="w-full mx-auto max-w-[1792px] flex items-center justify-between p-2"
        ref={catMenu}
      >
        {/* <div className="flex sm:flex-col justify-center items-center w-[56%] md:w-full gap-8"> */}
        <div>
          <Img
            src="images/logo.png"
            alt="imageseven_one"
            className="sm:w-[60%]"
          />
        </div>
          <Img
            src="images/img-burger.svg"
            alt="imageseven_one"
            className="w-[8%] sm:block hidden"
            onClick={() => setIsMobileMenuOpen(true)}
          />
        <div className="flex w-full sm:hidden justify-center">
          {PAGES.map((p, i) =>
            p.urls.indexOf(location.pathname) !== -1 ? (
              <div className="flex flex-col items-center gap-2" key={i}>
                <Text
                  as="p"
                  className="mt-[9px] !text-teal-300 tracking-[0.50px]"
                >
                  {p.title}
                </Text>
                <Img
                  src="images/img_selection_indicator.svg"
                  alt="selection_one"
                  className="h-[3px] w-full"
                />
              </div>
            ) : (
              <div
                className="flex p-2.5 cursor-pointer"
                onClick={() => navigate(p.urls[0])}
                key={i}
              >
                <div className="flex">
                  <Text as="p" className="self-start tracking-[0.50px]">
                    {p.title}
                  </Text>
                </div>
              </div>
            )
          )}
        </div>
        <div className="flex justify-end items-center w-[40%] md:w-full gap-2.5 sm:hidden">
          {credits && <div> Credits: {credits}$ </div>}
          <Img
            src="images/user.png"
            alt="circleimage"
            className="h-[40px] w-[40px] rounded-[50%]"
          />
          <div className="flex items-center gap-2.5">
            <Heading
              size="s"
              as="h6"
              className="self-start !text-gray-900 !font-montserrat text-center"
            >
              {userObj?.name.split(" ")[0] || "User"}
            </Heading>
            {/* Hamburger Menu Icon */}
            <Img
              src="images/img_arrow_down.svg"
              alt="menu_icon"
              className="h-[24px] w-[24px]"
              onClick={handleMenuClick}
            />
            {isMenuOpen && (
              <div
                className={
                  "absolute top-[14%] right-10 bg-gray-100 w-[150px] rounded shadow-lg z-50"
                }
              >
                <ul className="list-none p-2 text-left">
                  <li
                    className="flex items-center hover:bg-gray-200 px-2 py-1 cursor-pointer"
                    onClick={() => handleMenuItemClick("logout")}
                  >
                    <Img
                      src="images/img_settings.svg"
                      alt="settings"
                      className="h-[24px] w-[24px] mr-2"
                    />
                    Logout
                  </li>
                  <li className="border-gray-300 h-2 border-b" />
                  <li
                    className="flex items-center hover:bg-gray-200 px-2 py-1 cursor-pointer"
                    onClick={() => handleMenuItemClick("settings")}
                  >
                    <Img
                      src="images/img_logout.svg"
                      alt="settings"
                      className="h-[24px] w-[24px] mr-2"
                    />
                    Settings
                  </li>
                  {/* Add more menu items as needed */}
                </ul>
              </div>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>

      {isMobileMenuOpen && (
        <div className="navbar-menu relative z-50">
          {/* <div class="navbar-backdrop fixed inset-0 bg-white-100 opacity-21"></div> */}
          <nav className="fixed top-0 left-0 bottom-0 flex flex-col  py-6 px-6 border-r overflow-y-auto bg-white-A700">
            <div className="flex items-center mb-8 justify-between">
              <Img
                src="images/logo.png"
                alt="imageseven_one"
                className="w-[40%]  object-cover"
              />
              <Img
                src="images/img_close_teal_300.svg"
                alt="close"
                onClick={() => setIsMobileMenuOpen(false)}
              />
            </div>
            <div>
              <ul>
                {PAGES.map((p, i) => (
                  <li className="mb-1" key={i}>
                    {" "}
                    {/* Added key prop for uniqueness */}
                    <a
                      className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                      href={p.urls[0]} // Assuming the first URL in urls should be used
                    >
                      {p.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
}
