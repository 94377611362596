const categories = require('./categories.json');

function categoryTypes() {
    return categories.map(item => item.type);
}

function getCategoryByType(type) {  
  const matchingCategory = categories.find(category => category.type === type);
  return matchingCategory?.appearances;
}

function getStatusColor(status) {
    switch (status) {
      case "complete":
        return "rgb(106 224 224)";
      case "error":
        return "#ff6868";
      case "running":
        return "rgb(144 187 240)";
      default:
        return "gray"; // Default color for unexpected statuses
    }
  }


const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // Function to recursively convert nested structures to CSV format
    const processValue = (value) => {
        if (value == null || value == undefined) return ''
        if (Array.isArray(value)) {
            return value.map(processValue).join(' | '); // Join list elements with comma
        } else if (typeof value === 'object') {
            return Object.values(value).map(processValue).join(' | '); // Join object values with comma
        } else {
            return value; // No modification for simple values
        }
    };
    const delimeter=','
    // Generate header row
    const header = Object.keys(array[0]).map(key => key.toUpperCase());
    str += header.join(delimeter) + '\r\n';

    // Process each data row
    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            const value = array[i][index];
            const new_value=processValue(value)
            // line += (line !== '' ? delimeter : '') +new_value.replace(/,/g, '|');
        }
        str += line + '\r\n';
    }

    return str;
};
const getCredits = (len, credit) => {
  if (typeof len !== 'number' || Number.isNaN(len)) {
    return 0; // Or throw an error if appropriate
  }

  const result = len <= 1000 ? 5 : credit * len;
  return Number(result.toFixed(2));
};

function formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }

module.exports = { convertToCSV ,getCredits,formatTimestamp,categoryTypes,getCategoryByType,getStatusColor}