import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img, Input, Warning } from "../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotUserPassword } from "store/slices/UserSlice";
import CustomInput from "components/CustomInput";
import { isValidEmail } from "utils/validations";
import { Spinner } from "components/Spinner";
export default function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [cBtn, setCBtn] = useState(true);
  useEffect(() => {
    setCBtn(isValidEmail(email))
  }, [email])
  const handleForgotPassword = async () => {
    if (!email) return
    setLoading(true)
    try {
      await dispatch(forgotUserPassword(email))
      navigate("/verification-link");
    } catch (error) {
      console.error(error)
      setError(error?.response?.data?.message || 'Failed to forgot. Please try again later.');
    }
    setLoading(false)
  }
  return (
    <>
      <Helmet>
        <title>Local Business Lead List</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex justify-center items-center h-[1080px] w-full md:h-auto px-14 py-[300px] md:p-5 bg-[url(/public/images/img_beanstalk_map_scrapper.png)] bg-cover bg-no-repeat">
        <div className="flex flex-col items-center w-[26%] md:w-full gap-[31px] p-8 sm:p-5 bg-white-A700 shadow-xs rounded-[16px]">
          {
            error &&
            <Warning text={error} showImage={false} />
          }
          <Img src="images/logo.png" alt="imageseven_one" className="w-[50%] object-cover" />
          <div className="flex flex-col items-center w-[86%] md:w-full gap-1">
            <a href="#">
              <Text size="lg" as="p" className="tracking-[0.18px]">
                Forgot password?
              </Text>
            </a>
            <Text as="p" className="!text-gray-600 tracking-[0.15px] text-center leading-6">
              Enter your email for the verification proccess, we will send the link to your email.
            </Text>
          </div>
          <CustomInput value={email} onChange={(e) => setEmail(e.target.value)} />
          <div className="flex flex-col self-stretch gap-4">
            {
              loading ?
                <div className="flex justify-center">
                  <Spinner />
                </div>
                :
                <Button
                  disabled={!cBtn}
                  variant="gradient"
                  shape="round"
                  color="teal_300_teal_600"
                  className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium"
                  onClick={handleForgotPassword}
                >
                  Continue
                </Button>
            }
            <Button shape="round" className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium" onClick={() => navigate(-1)}>
              Back
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}