import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Map, Button, Text, Heading, Img } from "../../components";

import Suggestions from "../../components/Suggestions";
import Header from "../../components/Header";
import TaskConirmation from "../../modals/TaskConirmation";
import CustomInput from "components/CustomInput";
import { Spinner } from "components/Spinner";
import { getCredits } from "utils/utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { State } from "country-state-city";
import { categoryTypes } from "../../utils/utils";

import {
  GOOGLE_MAP_KEY,
  MIN_SEARCH,
  SELECTEE_STATES,
  WHOLE_USA,
  NEARBY_AREA,
} from "config/config";
import MultipleSelectInput from "../../components/MultiSelectInput";
import { BsArrowDownRight } from "react-icons/bs";
import axios from "axios";
import QueryInfo from "modals/QueryInfo";
import { getCreditCost, getNearbyAddress } from "api/auth";
import { useLocation } from "react-router-dom";
const NOT_VALID = "Not Valid";
const TABS = [NEARBY_AREA, SELECTEE_STATES, WHOLE_USA];
const stateNames = State?.getStatesOfCountry("US").map((state) => state.name);
const MAXIMUM_QUERIES = 10;

export default function Home() {
  const CATEORIES = categoryTypes();
  const user = useSelector((state) => state.user.userObj);
  const [tName, setTName] = useState("");
  const [error, setError] = useState(false);
  const [queries, setQueries] = useState([]);
  const [tab, setTab] = useState(NEARBY_AREA);
  const [count, setCount] = useState(MIN_SEARCH);
  const RADIUS_OPTIONS = [
    "10 Miles",
    "25 Miles",
    "50 Miles",
    "100 Miles",
    "200 Miles",
    "300 Miles",
    "500 Miles",
  ];
  // const [tab, setTab] = useState("Selected States")
  const [zipCode, setZipcode] = useState("");
  const [location, setLocation] = useState("");
  const [zLoading, setZLoading] = useState(false);
  const [sBtn, setSBtn] = useState(false);
  const [states, setStates] = useState([]);
  const [radius, setRadius] = useState([RADIUS_OPTIONS[2]]);
  const [markers, setMarkers] = useState([]);
  const [nearbyAddress, setNearbyAddress] = useState([]);
  // const [markers, setMarkers] = useState([
  // { address: "", loc: { lat: 40.7127753, lng: -74.0059728 } },
  // ]);
  const [creditPrice, setCreditPrice] = useState(undefined);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // State for TaskConfirmation modal
  const [isInfoOpen, setIsInfoOpen] = useState(false); // State for TaskConfirmation modal
  const [tutorial, setTutorial] = useState(false);
  const { state } = useLocation();

  const handleResetButton = () => {
    setTName("");
    setQueries([]);
    setLocation("");
    setCount(MIN_SEARCH);
    setZipcode("");
    setTab(NEARBY_AREA);
  };
  function milesToKilometers(miles) {
    return miles * 1.60934;
  }
  function getRadius() {
    const val = radius.length === 0 ? RADIUS_OPTIONS[0] : radius[0];
    let r = parseInt(val.replace(" Miles", "").trim());
    return milesToKilometers(r) * 1000;
  }
  useEffect(() => {
    const getPrice = async () => {
      try {
        const data = await getCreditCost(user.token);
        setCreditPrice(data.credits);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    getPrice();
  }, []);

  useEffect(() => {
    if (state) {
      setTab(state.tPayload?.searchOption);
      setTName(state.tName);
      setQueries(state.tPayload?.queries);
      setStates(state.tPayload?.states);
      setCount(state.tPayload?.count);
      if (state.tPayload.nearby) {
        if (state.tPayload.nearby.zipcode) {
          setLocation(state.tPayload.nearby.zipcode);
        } else {
          setLocation("");
        }
      }
    }
  }, [state]);

  const handleSubmitButton = async () => {
    if (!tName) toast.error("Enter task name");
    else if (queries.length === 0) toast.error("Search query missing");
    else if (!count || count < MIN_SEARCH)
      toast.error(`Minimum search results is ${MIN_SEARCH}`);
    // else if (tab === NEARBY_AREA && !zipCode) toast.error("Zipcode missing")
    else if (tab === "Selected States" && states.length === 0)
      toast.error("States Missing");
    else {
      if (tab === NEARBY_AREA) {
        if (!zipCode || zipCode === NOT_VALID) {
          const z = await checkAddress();
          if (!z || z == NOT_VALID) {
            setError(true);
            toast.error("Location is not correct");
            return;
          }
        }
        if (nearbyAddress.length === 0) {
          toast.warn("No nearby ZIP codes found. Please select a different ZIP code or increase the radius.");
          return;
        }
      }
      if (queries[0].indexOf(",") != -1) {
        toast.error("Invalid query");
        return;
      }
      setIsConfirmationOpen(true);
    }
  };
  const getPostalCode = (data) => {
    for (let i = 0; i < data.length; i++) {
      const addressComponents = data[i].address_components;
      for (let j = 0; j < addressComponents.length; j++) {
        const component = addressComponents[j];
        if (component.types.includes("postal_code")) {
          return component;
        }
      }
    }
    return null; // Return null if postal code is not found
  };
  async function getZipCodeFromLatLong(loc) {
    setZLoading(true);
    try {
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${loc.lat},${loc.lng}&key=${GOOGLE_MAP_KEY}`;
      const response = await axios.get(url);
      const component = getPostalCode(response.data.results);
      const zc = component.long_name;
      setZipcode(zc);
      setLocation(zc);
      setZLoading(false);
      return zc;
    } catch (err) {
      setZipcode(NOT_VALID);
      setError(true);
      console.log("Zipcode fetch -> ", err);
      setZLoading(false);
      return NOT_VALID;
    }
  }
  const closeConfirmationModal = () => {
    setIsConfirmationOpen(false);
  };
  useEffect(() => {
    const cached = localStorage.getItem("show-first-time-tutorial");

    if (cached === "dismissed") {
      setTutorial(false);
      return;
    }

    if (cached === null) {
      setTutorial(true);
    }
  }, []);
  const getLatLongFromAddress = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${GOOGLE_MAP_KEY}`
      );
      if (!response.ok) {
        setError(true);
        return NOT_VALID;
      }
      const data = await response.json();
      if (data.status === "OK") {
        return data.results[0].geometry.location;
      } else {
        console.error(data.status); // Handle specific errors from the API
      }
    } catch (error) {
      console.error(error.message);
    }
    return NOT_VALID;
  };
  // const handleZipcodeChange = (event) => {
  //   if (event.target.value === "") {
  //     setZipcode("");
  //     return;
  //   }
  //   // Regular expression to match only digits (0-9)
  //   const regex = /^\d+$/;

  //   // Update state only if the new value matches the regex
  //   const newValue = event.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  //   if (regex.test(newValue)) {
  //     setZipcode(newValue);
  //   }
  // };
  const handleCountChange = (event) => {
    if (event.target.value) setCount(parseInt(event.target.value, 10));
    else setCount("");
  };
  const checkAddress = async () => {
    if (!location) return;
    const l = await getLatLongFromAddress(location);
    setMarkers([{ address: "", loc: l }]);
    return await getZipCodeFromLatLong(l);
  };
  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      const input = event.target.value;
      console.log(await checkAddress(input));
    }
  };
  const handleMapSelect = async (l) => {
    setMarkers([{ address: "", loc: l }]);
    await getZipCodeFromLatLong(l);
  };

  const handleSetRadius = async (radius) => {
    setRadius(radius);
  };

  useEffect(() => {
    const handleNearbyAddress = async () => {
      if (zipCode && radius.length > 0) {
        try {
          const data = await getNearbyAddress(zipCode, getRadius());
          setNearbyAddress(data);
        } catch (err) {
          console.error(err);
          toast.error("Error in fetching nearby zipcodes");
        }
      }
    };
    handleNearbyAddress();
  }, [radius, zipCode, tab]);

  // useEffect(() => {
  //   const isUnique = (item, index, array) => {
  //     const indexFound = array.findIndex(
  //       (elem) =>
  //         elem.loc &&
  //         elem.loc.lat === item.loc.lat &&
  //         elem.loc.lng === item.loc.lng
  //     );
  //     return index === indexFound;
  //   };
  //   if (tab == NEARBY_AREA) {
  //     const filteredData = nearbyAddress.filter(isUnique);
  //     setMarkers(filteredData);
  //   }
  // }, [nearbyAddress, tab]);

  const handleSetQueries = async (queries) => {
    if (queries.length > MAXIMUM_QUERIES) {
      toast.warn(`You can select a maximum of ${MAXIMUM_QUERIES} queries`);
      return;
    }
    setQueries(queries);
  };
  const handleSetStates = async (states) => {
    setStates(states);

    // Filter states not present in markers
    const filteredStates = states.filter(
      (state) => !markers.some((marker) => marker.address === state)
    );

    // Get locations for filtered states
    const results = [];
    for (const state of filteredStates) {
      try {
        const loc = await getLatLongFromAddress(state);
        results.push({ address: state, loc });
      } catch (error) {
        console.error(`Error getting coordinates for ${state}:`, error);
      }
    }

    // Update markers with new locations and remove markers not in states
    const updatedMarkers = markers.filter((marker) =>
      states.includes(marker.address)
    ); // Keep only markers in states
    updatedMarkers.push(...results); // Add new markers

    setMarkers(updatedMarkers);
  };

  const handleInfoOpen = () => {
    setIsInfoOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Local Business Lead List</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col w-full gap-4 p-8 sm:p-5 bg-gray-50">
        <Header />
        <div className="w-full mx-auto max-w-[1856px]">
          <div className="flex md:flex-col justify-center gap-4">
            <div className="w-[29%] md:w-full p-5 bg-white-A700 rounded-[16px]">
              <div className="flex mt-3">
                {TABS.map((txt, i) =>
                  txt === tab ? (
                    <div className="flex flex-col items-center gap-[5px]">
                      <div className="flex mt-2">
                        <Text
                          as="p"
                          className="self-start !text-teal-300 tracking-[0.50px]"
                        >
                          {txt}
                        </Text>
                      </div>
                      <Img
                        src="images/img_selection_indicator.svg"
                        alt="selection_three"
                        className="h-[3px] w-full"
                      />
                    </div>
                  ) : (
                    <div
                      className="flex p-2.5 cursor-pointer"
                      onClick={() => {
                        setTab(txt);
                        setMarkers([]);
                      }}
                    >
                      <div className="flex">
                        <Text as="p" className="self-end tracking-[0.50px]">
                          {txt}
                        </Text>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="h-px mt-4 bg-gray-50" />
              <CustomInput
                label="Task Name"
                placeholder="Enter a name for your task"
                value={tName}
                onChange={(e) => setTName(e.target.value)}
              />
              <div className="mt-2.5">
                <MultipleSelectInput
                  options={CATEORIES}
                  label="Search Query"
                  placeholder="Select your queries"
                  selected={queries}
                  setSelected={handleSetQueries}
                  customInput={true}
                />
                {/* <CustomInput label="Search Query" placeholder='Enter your search query' value={query} onChange={(e) => { setQuery(e.target.value); setError(false) }} onKeyDown={tab === NEARBY_AREA ? handleKeyDown : null} warn={error} suffix={tab==NEARBY_AREA?<Img src="images/img_info.svg" alt="mingcute:pdf-fill" onClick={()=>setIsInfoOpen(true)}/>:null}/> */}
                {/* <CustomInput label="Search Query" placeholder={tab === NEARBY_AREA ? "Search Query Location" : "Query to search"} value={query} onChange={(e) => setQuery(e.target.value)} onKeyDown={tab === NEARBY_AREA ? handleKeyDown : null} /> */}
                <Suggestions
                  className="flex flex-col items-start gap-2"
                  options={
                    tab === NEARBY_AREA
                      ? ["Doctor", "Hotels", "Atm"]
                      : undefined
                  }
                />
              </div>
              {tab === NEARBY_AREA && (
                <>
                  <div className="flex flex-col items-start mt-[15px] gap-2">
                    <CustomInput
                      label="Search Location"
                      placeholder={
                        error
                          ? "Invalid location"
                          : "Enter your search location here"
                      }
                      value={location}
                      onChange={(e) => {
                        setLocation(e.target.value);
                        setError(false);
                      }}
                      onKeyDown={handleKeyDown}
                      warn={error}
                      suffix={
                        <Img
                          src="images/img_info.svg"
                          alt="mingcute:pdf-fill"
                          onClick={handleInfoOpen}
                        />
                      }
                    />
                    <div className="w-[100%]">
                      <MultipleSelectInput
                        options={RADIUS_OPTIONS}
                        label="Map Radius"
                        placeholder="Select Radius Here"
                        selected={radius}
                        setSelected={handleSetRadius}
                        multiselect={false}
                      />
                    </div>
                    {/* {
                      zLoading ? <Spinner /> :
                        <Input
                          shape="round"
                          name="inputfield_one"
                          placeholder={`Selected Zipcode`}
                          className="gap-[35px]"
                          value={zipCode}
                          onChange={handleZipcodeChange}
                        />
                    } */}
                  </div>
                </>
              )}
              {tab === SELECTEE_STATES && (
                <MultipleSelectInput
                  options={stateNames}
                  label="Select State"
                  placeholder="Search required states in here"
                  selected={states}
                  setSelected={handleSetStates}
                  multiselect={true}
                />
              )}
              {/* <div className="flex flex-col mt-4 gap-2">
                <CustomInput label="Clay webhook" placeholder="Post results to clay table" required={false} />
              </div> */}
              <div className="flex flex-col mt-4 gap-2">
                <CustomInput
                  label="Number of Results"
                  placeholder="Required results count"
                  value={count}
                  onChange={handleCountChange}
                />
              </div>
              {creditPrice === undefined ? (
                <Spinner />
              ) : (
                <div className="flex flex-col items-start mt-4 p-[15px] bg-gray-50 rounded-[16px]">
                  <div className="flex self-stretch justify-between items-center gap-5 flex-wrap">
                    <Text
                      size="s"
                      as="p"
                      className="tracking-[1.25px] uppercase"
                    >
                      Estimated cost to run
                    </Text>
                    <Heading as="h1" className="self-start tracking-[0.15px]">
                      {getCredits(count, creditPrice)}$
                    </Heading>
                  </div>
                  <Text
                    size="xs"
                    as="p"
                    className="!text-gray-900_99 tracking-[0.40px]"
                  >
                    This amount will be deducted on successful scraping.
                  </Text>
                </div>
              )}
              <div className="flex justify-center mt-4 gap-4">
                {zLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <Button
                      shape="round"
                      className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium"
                      onClick={handleResetButton}
                    >
                      Reset
                    </Button>

                    <Button
                      variant="gradient"
                      shape="round"
                      color="teal_300_teal_600"
                      className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium"
                      onClick={handleSubmitButton}
                    >
                      Submit
                    </Button>
                  </>
                )}
              </div>
            </div>
            <Map
              markers={markers}
              className="h-[888px] sm:h-[400px] bg-white-A700 flex-1 rounded-[16px]"
              handleMapSelect={handleMapSelect}
              radius={getRadius()}
              tab={tab}
            />
          </div>
        </div>
      </div>
      {tutorial && (
        <div
          className="fixed flex  gap-4 px-4 items-center justify-center w-full h-full top-0 left-0 bg-black opacity-70 z-[999]"
          onClick={() => {
            localStorage.setItem("show-first-time-tutorial", "dismissed");
            setTutorial(false);
          }}
        >
          <p className="text-center text-lg text-teal-300">
            You can also double click on map to select location
          </p>
          <BsArrowDownRight className="opacity-70" size={30} color="#8adcbe" />
        </div>
      )}

      <TaskConirmation
        isOpen={isConfirmationOpen}
        handleClose={closeConfirmationModal}
        count={count}
        queries={queries}
        tab={tab}
        taskName={tName}
        token={user.token}
        states={states}
        nearby={{
          zipcode: zipCode,
          zip_count: nearbyAddress.length,
          radius: getRadius(radius),
        }}
        setTab={setTab}
        setCount={setCount}
      />
      <QueryInfo
        isOpen={isInfoOpen}
        handleClose={() => {
          setIsInfoOpen(false);
        }}
      />
    </>
  );
}
