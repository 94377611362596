import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { signIn } from "api/auth";
import { forgotPassword } from "api/auth";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userCred) => {
    try {
      const data=await signIn(userCred)
      const token=data.token
      // Cookies.set("token",token, { expires: 45 });
      return {token,name:data.name,email:userCred.email};
    } catch (error) {
      console.error("Error:", error);
      throw new Error(error.response?.data?.message || "Error occurred");
    }
  }
);

export const forgotUserPassword = createAsyncThunk(
  "user/forgotPassword",
  async (email) => {
    try {
      const data = await forgotPassword(email);
      const {token, type, name} = data
      // Cookies.set("token",token, { expires: 45 });
      return {token, type, name, email};
    } catch (error) {
      console.error("Error:", error);
      throw new Error(error.response?.data?.message || "Error occurred");
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    userObj: null,
  },
  reducers: {
    setUser: (state, action) => {
      const { email, name,token } = action.payload;
      state.userObj = { email, name,token };
      // Cookies.set("token", token, { expires: 30 });
    },
    clearUser: (state) => {
      state.userObj = null;
      // Cookies.remove("token");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.userObj = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userObj = action.payload;
      })
      .addCase(forgotUserPassword.pending, (state) => {
        state.userObj = null;
      })
      .addCase(forgotUserPassword.fulfilled, (state, action) => {
        state.userObj = action.payload
      });
  },
});

export default userSlice.reducer;
export const { clearUser,setUser } = userSlice.actions;
