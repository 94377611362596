import axios from "axios";
import { BACKEND_URL } from "../config/config";

const signIn = async (userCred) => {
  const response = await axios.post(`${BACKEND_URL}/auth/login`, userCred);
  return response.data;
};

const signUp = async (userCred) => {
  const response = await axios.post(`${BACKEND_URL}/auth/signup`, userCred);
  return response.data;
};

const resetPassword = async (passCred) => {
  const response = await axios.put(
    `${BACKEND_URL}/auth/update-password`,
    passCred
  );
  return response.data;
};

const verifyOtp = async (email, otp) => {
  const response = await axios.put(`${BACKEND_URL}/auth/verify-otp`, {
    email,
    otp,
  });
  return response.data;
};


const getUser = async (token) => {
  const response = await axios.get(
    `${BACKEND_URL}/auth/get-user?token=${token}`
  );
  return response.data;
};

// const getTasks = async (token) => {
// const response = await axios.get(`${BACKEND_URL}/api/v1/results?token=${token}`);
// return response.data;
// };

const getTasks = async (token, filter = "all") => {
  const response = await axios.get(`${BACKEND_URL}/api/v1/tasks`, {
    params: {
      token,
      type: filter,
    },
  });
  return response.data;
};

const getCredits = async (token) => {
  const response = await axios.get(`${BACKEND_URL}/auth/credits?token=${token}`);
  return response.data;
};

const getEvTasks = async (token) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/v1/ev-results?token=${token}`
  );
  return response.data;
};

const addGoogleMapTask = async (token, payload) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/v1/google-map-task?token=${token}`,
    payload
  );
  return response.data;
};

const deleteTask = async (token,id, type) => {
  const response = await axios.delete(
    `${BACKEND_URL}/api/v1/task/${id}?token=${token}`,
    {
      params: { type },
    }
  );
  return response.data;
};

const addJobsTask = async (token, payload) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/v1/jobs-task?token=${token}`,
    payload
  );
  return response.data;
};

const addEmailValidationTask = async (token, payload) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/v1/email-validation-task?token=${token}`,
    payload
  );
  return response.data;
};

const emailValidation = async (token, email) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/v1/email-validation?token=${token}&email=${email}`
  );
  return response.data;
};

const createCustomPortal = async (token) => {
  const response = await axios.post(
    `${BACKEND_URL}/custom-portal?token=${token}`,
    {
      returnUrl: window.location.href,
    }
  );
  return response.data;
};

const getCreditCost = async (token) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/v1/get-credits?token=${token}`
  );
  return response.data;
};

const downlaodResult = async (id, type) => {
  const response = await axios.get(`${BACKEND_URL}/api/v1/download/${id}`, {
    params: { type },
  });
  return response.data;
};

const forgotPassword = async (email) => {
  const response = await axios.post(`${BACKEND_URL}/auth/forgot-password`, {
    email,
  });
  return response.data;
};

const getNearbyAddress = async (zipcode, distance) => {
  const response = await axios.post(`${BACKEND_URL}/api/v1/nearby-zip`, {
    zipcode,
    distance,
  });
  return response.data;
};

export {
  signIn,
  signUp,
  verifyOtp,
  getTasks,
  addJobsTask,
  createCustomPortal,
  downlaodResult,
  forgotPassword,
  resetPassword,
  getNearbyAddress,
  emailValidation,
  getCredits,
  getCreditCost,
  addEmailValidationTask,
  getEvTasks,
  addGoogleMapTask,
  getUser,
  deleteTask,
};
