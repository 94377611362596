import React, { useEffect, useState } from "react";
import { useRoutes, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import ForgotPassword from "pages/ForgotPassword";
import Results from "pages/Results";
import Verify from "pages/Verify";
import Success from "pages/Success";
import Settings from "pages/Settings";
import VerificationLink from "pages/VerificationLink";
import ResetPassword from "pages/ResetPassword";
import { PRIVILEGEDEMAILS } from "./config/config";
import {getUser } from "api/auth";
import { Spinner } from "components/Spinner";

const checkTokenValidity = (token) => {
  if (!token) return false;

  const tokenPayload = JSON.parse(atob(token.split('.')[1]));
  const expiry = tokenPayload.exp * 1000; // Convert expiry to milliseconds
  return expiry > Date.now();
};

const verifyTokenWithBackend = async (token) => {
  try {
    const data=await getUser(token);
    window.Atlas.call("identify", {
      userId: data._id, // Use actual userId
      name: data.name, // Use actual user name
      email: data.email, // Use actual user email
      customFields: {
        product: "Local Business Lead List",
      },
    });
    return true
  } catch (error) {
    console.error("Error verifying token with backend:", error);
    return false;
  }
};

const PrivateRoute = ({ element }) => {
  const { pathname } = useLocation()
  const { userObj } = useSelector((state) => state.user);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isCheckingToken, setIsCheckingToken] = useState(true);
  const [isResetPassword, setIsResetPassword] = useState(false);
  


  useEffect(() => {
    const checkToken = async () => {
      if (userObj && userObj.token) {
        if(userObj.type && userObj.type === 'reset-password') {
          setIsTokenValid(false);
          setIsCheckingToken(false);
          setIsResetPassword(true);
          return;
        } else {
          const localValid = checkTokenValidity(userObj.token);
          if (!localValid) {
            setIsTokenValid(false);
            setIsCheckingToken(false);
            return;
          }
          else {
            const backendValid = await verifyTokenWithBackend(userObj.token);
            setIsTokenValid(backendValid);
          }
        }
      } else {
        setIsTokenValid(false);
      }
      setIsCheckingToken(false);
    };

    checkToken();
  }, [userObj]);

  if (isCheckingToken) {
    return <div className="flex justify-center items-center w-screen h-screen"><Spinner /></div>; 
  }

  if (!userObj || !isTokenValid) {
    if(pathname === '/reset-password' && isResetPassword) {
      return element;
    }
    return <Navigate to="/sign-in" />;
  }

  return element;
};

const ProjectRoutes = () => {
  const { userObj } = useSelector((state) => state.user);
  const isUserSignedIn = userObj !== null;

  const PAGES = [
    { title: "Results", urls: ["/results"], element: <Results /> },
    { title: "Google Maps", urls: ["/google-maps"], element: <Home /> },
  ];

  // const additionalPages = [
  //   { title: "Jobs", urls: ["/jobs"], element: <Jobs /> },
  //   { title: "Email Validation", urls: ["/email-validation"], element: <EmailValidation /> },
  // ];

  // if (PRIVILEGEDEMAILS.includes(userObj?.email)) {
  //   PAGES.push(...additionalPages);
  // }

  const routes = [
    {
      path: "sign-in",
      element: <SignIn />,
    },
    {
      path: "/",
      element: <Navigate to="/google-maps" />,
    },
    {
      path: "/home",
      element: <Navigate to="/google-maps" />,
    },
    {
      path: "/google-maps",
      element: <PrivateRoute element={<Home />} />,
    },
    {
      path: "/settings",
      element: <PrivateRoute element={<Settings />} />,
    },
    {
      path: "/success",
      element: <Success />,
    },
    {
      path: "sign-up",
      element: <SignUp />,
    },
    {
      path: "verify",
      element: <Verify />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "verification-link",
      element: <VerificationLink />
    },
    {
      path: "reset-password",
      element: <PrivateRoute element={<ResetPassword />} />
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  // Add the privileged routes if the user is signed in and has a privileged email
  if (isUserSignedIn) {
    PAGES.forEach((page) => {
      routes.push({
        path: page.urls[0],
        element: <PrivateRoute element={page.element} />,
      });
    });
  }

  const element = useRoutes(routes);

  return element;
};

export default ProjectRoutes;
