import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Text, Input, PasswordInput, Warning, Heading } from "../../components";
import { useNavigate } from "react-router-dom";
import CustomInput from "components/CustomInput";
import { signUp } from "api/auth";
import { checkPasswordStrength, isValidEmail } from "../../utils/validations"
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import { BACKEND_URL } from "config/config";
export default function SignUp() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [cBtn, setCBtn] = useState(true);
  const [pVal, setPVal] = useState({ 1: true, 2: true, 3: true })
  const [inpval, setInpval] = useState({
    name: '',
    email: '',
    password: '',
    cPassword: ''
  });

  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "password") {
      const { rules, isStrong } = checkPasswordStrength(inpval.password, inpval.name)
      setPVal(rules)
    };
    if (name === "email") isValidEmail(value)
  }
  useEffect(() => {
    setError('')
    const { rules, isStrong } = checkPasswordStrength(inpval.password, inpval.name)
    const con = inpval.name && isValidEmail(inpval.email) && isStrong && inpval.password === inpval.cPassword
    setCBtn(con)
  }, [inpval])
  const handleSignup = async () => {
    const { name, email, password } = inpval; // Destructure within signUp
    setLoading(true)
    try {

      await signUp({ name, email, password });
      navigate('/verify', { state: { email: inpval.email } })
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
      else {
        setError('Failed to register. Please try again later.');
      }
    }
    setLoading(false)
  }
  const handleGoogleLogin = async () => {
    try {
      const redirectUrl = encodeURIComponent(`${window.location.origin}/success`);
      window.location.href = `${BACKEND_URL}/auth/google?redirectUrl=${redirectUrl}`;
    } catch (error) {
      console.error('Google login error:', error);
    }
  };
  return (
    <>
      <Helmet>
        <title>Local Business Lead List</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex justify-center items-center h-[1080px] w-full md:h-auto px-14 py-20 md:p-5 bg-[url(/public/images/img_beanstalk_map_scrapper.png)] bg-cover bg-no-repeat">
        <div className="flex flex-col items-center w-[26%] md:w-full gap-[31px] p-8 sm:p-5 bg-white-A700 shadow-xs rounded-[16px]">

          {
            error &&
            <Warning text={error} showImage={false} />
          }
          {/* <Img src="images/logo.png" alt="imageseven_one" className="w-[50%] object-cover" /> */}
          <Text as="h2" className="!text-teal-500 tracking-[0.15px] capitalize text-xl">
            Local Business Lead List
          </Text>
          <div className="flex flex-col items-center gap-[9px]">
            <Text size="md" as="p" className="tracking-[0.18px]">
              Welcome
            </Text>
            <Text as="p" className="!text-gray-600 tracking-[0.15px]">
              Sign up to Beanstalk to continue
            </Text>
          </div>
          <CustomInput label="Name" name="name" type="text" placeholder="Enter your name here" onChange={setVal} value={inpval.name} />
          <CustomInput label="Email Address" name="email" placeholder="Enter your email address in here" onChange={setVal} value={inpval.email} />
          <PasswordInput onChange={setVal} name="password" value={inpval.password} />
          <PasswordInput placeholder="Enter your password again in here" label="Confirm Password" onChange={setVal} name="cPassword" value={inpval.cPassword} />
          <div className="flex flex-col self-stretch gap-2">
            {
              ["Password Strength:", "At least 8 characters", "Contains a number or symbol", "Cannot contain your name or email address"].map((txt, i) => (
                <div className="flex items-center gap-2 flex-1">
                  {i !== 0 &&
                    < Img src={pVal[i] ? "images/img_check.svg" : "images/img_remove.svg"} alt="password" className="h-[18px] w-[18px]" />
                  }
                  <div className="flex">
                    <Text as="p" className={`self-end ${pVal[i] ? "!text-gray-600" : "!text-red-500"} tracking-[0.15px]`}>
                      {txt}
                    </Text>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="flex flex-col self-stretch items-center gap-[19px]">
            {
              loading ? <Spinner /> :
                <Button shape="round" className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium" onClick={handleSignup} disabled={!cBtn}>
                  Continue
                </Button>
            }
            <Text as="p" className="flex tracking-[0.50px]">
              <span className="text-gray-900">Already have an account?&nbsp;</span>
              <a href="sign-in" className="text-teal-600 font-bold underline">
                Log In
              </a>
            </Text>
          </div>
          <div className="flex sm:flex-col self-stretch justify-center items-center gap-4">
            <div className="sm:self-stretch h-px bg-gray-50 flex-1" />
            <Text as="p" className="self-start !text-gray-600 tracking-[0.50px]">
              OR
            </Text>
            <div className="sm:self-stretch h-px bg-gray-50 flex-1" />
          </div>
          <Button
            shape="round"
            leftIcon={<Img src="images/img_icon.svg" alt="↳ 📍icon " />}
            className="w-full gap-1.5 sm:px-5"
            onClick={handleGoogleLogin}
          >
            Continue with google
          </Button>
        </div>
      </div>
    </>
  );
}
