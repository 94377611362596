import React, { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { Helmet } from "react-helmet";
import { CloseSVG } from "../../assets/images";
import { Img, Text, SelectBox, Button, Heading, Input } from "../../components";
import Header from "components/Header";
import { ReactTable } from "../../components/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import { formatTimestamp, getStatusColor } from "utils/utils";
import { useMatch, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getTasks } from "api/auth";
import { Spinner } from "components/Spinner";
import Result from "modals/Result";
import { toast } from "react-toastify";
import ResultEmailValidation from "modals/ResultEmailValidation";
import ResultJobs from "modals/ResultJobs";
import DeleteTask from "modals/DeleteTask";
const dropDownOptions = [
  { label: "All", value: "all" },
  { label: "Jobs", value: "jobs" },
  { label: "Google Maps", value: "google-maps" },
  { label: "Email Validation", value: "email-validation" },
];

export default function Results() {
  const [searchBarValue12, setSearchBarValue12] = useState("");
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userObj);
  const token = user?.token;
  const [selection, SetSelection] = useState("google-maps");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [isResultOpen, setIsResultOpen] = useState(null);
  const [isDelOpen, setIsDelOpen] = useState(null);
  const [debouncedSearchValue] = useDebounce(searchBarValue12, 500);
  const closeResultModal = () => {
    setIsResultOpen(null);
  };

  const loadResults = async () => {
    setLoading(true);
    try {
      const dt = await getTasks(token, selection);

      setResults(dt);
    } catch (error) {
      console.error("Error making request:", error);
    } finally {
      setLoading(false);
    }
  };

  const realResults = useMemo(() => {
    if (debouncedSearchValue.length > 0) {
      return results.filter((result) =>
        result.name.toLowerCase().includes(debouncedSearchValue.toLowerCase())
      );
    } else {
      return results;
    }
  }, [results, debouncedSearchValue]);

  useEffect(() => {
    loadResults();
  }, [token, selection]);

  const handleBackHome = () => navigate("/");
  const tableColumns = React.useMemo(() => {
    const tableColumnHelper = createColumnHelper();
    return [
      tableColumnHelper.accessor("rowcalendarone", {
        cell: (info) => (
          <Text as="p" className="tracking-[0.50px]">
            {/* {new Date(info.row.original.timestamp).toLocaleString()} */}
            {formatTimestamp(info.row.original.timestamp)}
          </Text>
        ),
        header: (info) => (
          <div className="flex items-center gap-2 p-4">
            <Img
              src="images/img_calendar.svg"
              alt="calendar_one"
              className="h-[24px] w-[24px]"
            />
            <Heading
              as="h1"
              className="self-start !text-black-900_01 tracking-[0.15px] text-center"
            >
              Date
            </Heading>
          </div>
        ),
        meta: { width: "369px" },
      }),
      tableColumnHelper.accessor("rowemptyfolder", {
        cell: (info) => (
          <Text as="p" className="tracking-[0.50px]">
            {info.row.original.name}
          </Text>
        ),
        header: (info) => (
          <div className="flex items-start gap-2 p-[5px]">
            <Img
              src="images/img_empty_folder_svgrepo_com.svg"
              alt="emptyfolder_one"
              className="h-[24px] w-[24px] my-2.5"
            />
            <Heading
              as="h2"
              className="mt-2.5 !text-black-900_01 tracking-[0.15px] text-center"
            >
              Task Name
              {/* TODO add google sheet link */}
              {/* Sheet Link */}
            </Heading>
          </div>
        ),
        meta: { width: "353px" },
      }),
      tableColumnHelper.accessor("rowicbaselinefo", {
        cell: (info) => (
          <Text as="p" className="tracking-[0.50px] text-center">
            {info.row.original.count}
            {/* {info?.getValue?.()} */}
          </Text>
        ),
        header: (info) => (
          <div className="flex items-center gap-2 p-2.5">
            <Img
              src="images/img_ic_baseline_for.svg"
              alt="icbaselinefor"
              className="h-[24px] w-[24px] my-[5px]"
            />
            <Heading
              as="h3"
              className="!text-black-900_01 tracking-[0.15px] text-center"
            >
              DRC (data rows count)
            </Heading>
          </div>
        ),
        meta: { width: "353px" },
      }),
      tableColumnHelper.accessor("rowpriceone", {
        cell: (info) => (
          <Text as="p" className="tracking-[0.50px]">
            {info.row.original.payment?.amount}
          </Text>
        ),
        header: (info) => (
          <div className="flex items-start gap-2 p-[15px]">
            <Img
              src="images/img_price.svg"
              alt="price_one"
              className="h-[24px] w-[24px]"
            />
            <Heading
              as="h4"
              className="mt-0.5 !text-black-900_01 tracking-[0.15px] text-center"
            >
              Cost
            </Heading>
          </div>
        ),
        meta: { width: "353px" },
      }),
      tableColumnHelper.accessor("rowexclamation", {
        cell: (info) => {
          const color = getStatusColor(info.row.original.status);
          return (
            <div
              className={"rounded-[16px] text-center p-1 w-[94px]"}
              style={{ backgroundColor: color }}
            >
              <Text as="p" className={`tracking-[0.50px]`}>
                {info.row.original.status}
              </Text>
            </div>
          );
        },
        header: (info) => (
          <div className="flex items-center gap-2 p-4">
            <Img
              src="images/img_exclamation_teal_300.svg"
              alt="exclamation_one"
              className="h-[24px] w-[24px] ml-1"
            />
            <Heading
              as="h5"
              className="self-start !text-black-900_01 tracking-[0.15px] text-center"
            >
              Status
            </Heading>
          </div>
        ),
        meta: { width: "363px" },
      }),
      tableColumnHelper.accessor("rowemptyfolder", {
        cell: (info) => (
          <Text as="p" className="tracking-[0.50px] uppercase font-medium">
            {info.row.original.taskType}
          </Text>
        ),
        header: (info) => (
          <div className="flex items-start gap-2 p-[5px]">
            <Img
              src="images/img_empty_folder_svgrepo_com.svg"
              alt="emptyfolder_one"
              className="h-[24px] w-[24px] my-2.5"
            />
            <Heading
              as="h2"
              className="mt-2.5 !text-black-900_01 tracking-[0.15px] text-center"
            >
              Category
              {/* TODO add google sheet link */}
              {/* Sheet Link */}
            </Heading>
          </div>
        ),
        meta: { width: "353px" },
      }),
      tableColumnHelper.accessor("rowduplicate", {
        cell: (info) => (
          <div>
            <Img
              src="images/img_duplicate.svg"
              alt="duplicate"
              className="cursor-pointer transform transition-transform duration-200 hover:scale-125 hover:fill-green-300 min-w-[20px]"
              onClick={(event) => {
                event.stopPropagation(); // Stop the click event from propagating to the row
                if(info.row.original.taskType === "Google Maps") {
                  console.log(info.row.original);
                  navigate("/google-maps", { state: { tName: info.row.original.name, tPayload: info.row.original.payload } });
                } 
              }}
            />
          </div>
        ),
        header: (info) => <div className="flex items-start gap-2 p-[5px]" />,
        meta: { width: "120px" },
      }),
      tableColumnHelper.accessor("rowdelete", {
        cell: (info) => (
          <div>
            <Img
              src="images/img_bin.svg"
              alt="del"
              className="cursor-pointer transform transition-transform duration-200 hover:scale-125 hover:fill-red-300 min-w-[20px]"
              onClick={(event) => {
                event.stopPropagation(); // Stop the click event from propagating to the row
                setIsDelOpen({id:info.row.original._id,category:info.row.original.taskType,tName:info.row.original.name})
              }}
            />
          </div>
        ),
        header: (info) => <div className="flex items-start gap-2 p-[5px]" />,
        meta: { width: "120px" },
      })
    ];
  }, []);
  const handleRowClicked = (dt) => {
    if (dt.taskType !== "Google Maps") {
      setIsResultOpen(dt);
      return;
    }
    if (dt.status === "complete" && dt.payment?.status === "paid") {
      setIsResultOpen(dt);
    } else {
      toast.info("You can open the result when it's completed and paid");
    }
  };
  return (
    <>
      <Helmet>
        <title>Local Business Lead List</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col w-full gap-4 p-8 sm:p-5 bg-gray-50">
        <Header />
        <div className="w-full mx-auto max-w-[1856px] ">
          <div className="flex md:flex-col justify-between items-center gap-5 mb-3">
            <div className="flex justify-center w-[21%] md:w-full gap-4">
              <Input
                color="gray_50"
                size="md"
                variant="fill"
                name="search"
                placeholder={`Search `}
                value={searchBarValue12}
                onChange={(e) => setSearchBarValue12(e.target.value)}
                suffix={
                  searchBarValue12?.length > 0 ? (
                    <CloseSVG
                      onClick={() => setSearchBarValue12("")}
                      height={24}
                      width={24}
                      fillColor="#24262dff"
                    />
                  ) : (
                    <Img
                      src="images/img_rewind.svg"
                      alt="rewind"
                      className="cursor-pointer"
                    />
                  )
                }
                className="gap-[35px] sm:px-5 !text-gray-600_7e tracking-[0.10px] font-medium flex-grow rounded-[27px]"
              />
              <Button
                size="xl"
                variant="fill"
                shape="circle"
                className="w-[54px] !rounded-[27px]"
                onClick={loadResults}
              >
                <Img src="images/img_refresh.svg" />
              </Button>
            </div>
            {/* <SelectBox
              size="sm"
              shape="round"
              indicator={
                <Img src="images/img_icon_teal_300.svg" alt="↳ 📍icon " />
              }
              name="label_one"
              placeholder={`Google Maps`}
              options={dropDownOptions}
              className="w-[29%] gap-px sm:pr-5 tracking-[1.25px] uppercase font-medium"
              onChange={(e) => SetSelection(e.value)}
            /> */}
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <div>
              {realResults.length !== 0 ? (
                <div className="flex flex-col gap-4">
                  <div className="flex md:flex-col justify-between items-center gap-5">
                    <Text size="lg" as="p" className="tracking-[0.18px]">
                      <span className="text-black-900_01">
                        Total Results&nbsp;
                      </span>
                      <span className="text-teal-300">
                        &#123;{realResults.length}&#125;
                      </span>
                    </Text>
                  </div>
                  <div className="overflow-auto">
                    <ReactTable
                      size="xs"
                      headerProps={{
                        className:
                          "border-gray-50 border border-solid sm:flex-col rounded-lg",
                      }}
                      className="w-full"
                      rowDataProps={{ className: "md:flex-col" }}
                      columns={tableColumns}
                      data={realResults}
                      handleRowClicked={handleRowClicked}
                    />
                  </div>
                  {/* TODO pagination */}
                  {/* <div className="flex sm:flex-col justify-between items-center gap-5 p-2 border-gray-50 border border-solid rounded-lg">
                      <div className="flex justify-center items-center w-[11%] sm:w-full ml-2 gap-4 md:ml-0">
                        <Text as="p" className="self-end mb-[7px] tracking-[0.15px]">
                          Rows per page
                        </Text>
                        <SelectBox
                          size="sm"
                          shape="round"
                          indicator={<Img src="images/img_icon_teal_300.svg" alt="↳ 📍icon " />}
                          name="label_one"
                          placeholder={`12`}
                          options={dropDownOptions}
                          className="w-[39%] gap-px sm:pr-5 tracking-[1.25px] uppercase font-medium"
                        />
                      </div>
                      <div className="flex justify-between items-center w-[15%] sm:w-full mr-2 gap-5 md:mr-0">
                        <Text as="p" className="self-start tracking-[0.15px] text-right">
                          1-25 of 100
                        </Text>
                        <div className="flex gap-4">
                          <Img src="images/img_first_page.svg" alt="firstpage_one" className="h-[24px] w-[24px]" />
                          <Img src="images/img_arrow_left.svg" alt="arrowleft_one" className="h-[24px] w-[24px]" />
                          <Img src="images/img_arrow_right.svg" alt="arrowright_one" className="h-[24px] w-[24px]" />
                          <Img src="images/img_last_page.svg" alt="lastpage_one" className="h-[24px] w-[24px]" />
                        </div>
                      </div>
                    </div> */}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center px-14 md:p-5 bg-white-A700 rounded-[16px]">
                  <div className="flex flex-col items-center w-[17%] md:w-full gap-4">
                    <Img
                      src="images/img_frame.svg"
                      alt="image"
                      className="h-[262px] w-[262px]"
                    />
                    <div className="flex flex-col items-center gap-[19px]">
                      <Text as="p" className="!text-gray-500 tracking-[0.15px]">
                        You Have no new Results at the moment.
                      </Text>
                      <Button
                        variant="gradient"
                        shape="round"
                        color="teal_300_teal_600"
                        rightIcon={
                          <Img
                            src="images/img_arrowright.svg"
                            alt="arrow_right"
                          />
                        }
                        className="gap-2 tracking-[1.25px] uppercase font-medium min-w-[142px]"
                        onClick={handleBackHome}
                      >
                        Back home
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {isResultOpen?.taskType === "Google Maps" && (
          <Result isResult={isResultOpen} handleClose={closeResultModal} />
        )}
        {isResultOpen?.taskType === "Email Validation" && (
          <ResultEmailValidation
            isResult={isResultOpen}
            handleClose={closeResultModal}
          />
        )}
        {isResultOpen?.taskType === "Jobs" && (
          <ResultJobs isResult={isResultOpen} handleClose={closeResultModal} />
        )}
        {isDelOpen && (
          <DeleteTask
            isOpen={isDelOpen}
            handleClose={() => setIsDelOpen(null)}
            token
            payload={isDelOpen}
            handleRefresh={loadResults}
          />
        )}
      </div>
    </>
  );
}
