import React from "react";
import {
  GoogleMap,
  useJsApiLoader,
  OverlayView,
  Circle,
  Marker,
} from "@react-google-maps/api";
import {
  GOOGLE_MAP_KEY,
  NEARBY_AREA,
  SELECTEE_STATES,
  WHOLE_USA,
} from "../../config/config";
import { Spinner } from "components/Spinner";

const libraries = ["places"];

const Map = ({
  children,
  center,
  markers,
  handleMapSelect,
  setZipcode,
  className,
  radius,
  tab,
  ...restProps
}) => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries,
  });

  const handleMapClick = (event) => {
    const loc = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    handleMapSelect(loc);
  };
  let centerLocation = { lat: 40.7127753, lng: -74.0059728 };
  if (tab === NEARBY_AREA && center) {
    centerLocation = center;
  } else if (markers.length !== 0) {
    centerLocation = markers[0]["loc"];
  }

  let zoom = 9;
  if (tab === WHOLE_USA) {
    zoom = 3.8;
  } else if (tab === SELECTEE_STATES) {
    zoom = 5;
  }

  return (
    <div className={className}>
      {isLoaded ? (
        <div className={`${className} mt-2 border-4`}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={centerLocation}
            zoom={zoom}
            onDblClick={tab === NEARBY_AREA ? handleMapClick : undefined}
          >
            <Marker position={centerLocation} />
            {/* {markers.map((loc, i) => (
              
                tab === NEARBY_AREA ? (
                  <OverlayView
                  key={i}
                  position={loc.loc}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  getPixelPositionOffset={(width, height) => ({
                    x: -(width / 2),
                    y: -(height / 2),
                  })}
                >
                  <div
                    style={{
                      background: "white",
                      border: "1px solid black",
                      padding: "5px",
                      borderRadius: "5px",
                      width: "40px", // Adjust as needed
                      textAlign: "center",
                      opacity:0.8
                    }}
                  >
                    <span>{loc.zipcode}</span>
                  </div>
                </OverlayView>
                ) : (
                  <Marker position={loc["loc"]} />
                )
            )
            )} */}
            {tab === NEARBY_AREA && markers.length > 0 && (
              <Circle
                center={centerLocation}
                radius={radius}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: "#FF0000",
                  fillOpacity: 0.35,
                }}
              />
            )}
          </GoogleMap>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export { Map };
