import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Img, Text, Input, Warning, PasswordInput } from "../../components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "store/slices/UserSlice";
import CustomInput from "components/CustomInput";
import { isValidEmail } from "../../utils/validations"
import { Spinner } from "components/Spinner";
import { BACKEND_URL } from "config/config";
export default function SignIn() {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [cBtn, setCBtn] = useState(false);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [inpval, setInpval] = useState({
    email: '',
    password: '',
  });
  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    setError('')
    const con = isValidEmail(inpval.email) && inpval.password
    setCBtn(con)
  }, [inpval])

  const handleSignin = async (e) => {
    e.preventDefault();
    const { email, password } = inpval;
    setLoading(true)
    await dispatch(loginUser({ email, password }))
      .then((res) => {
        if (res.payload === undefined) {
          setError(res.error.message)
        } else navigate("/home")
      })
    setLoading(false)
  };
  const handleGoogleLogin = async () => {
    try {
      const redirectUrl = encodeURIComponent(`${window.location.origin}/success`);
      window.location.href = `${BACKEND_URL}/auth/google?redirectUrl=${redirectUrl}`;
    } catch (error) {
      console.error('Google login error:', error);
    }
  };
  return (
    <>
      <Helmet>
        <title>Local Business Lead List</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex justify-center items-center h-[1080px] w-full md:h-auto px-14 py-[200px] md:p-5 bg-[url(/public/images/img_beanstalk_map_scrapper.png)] bg-cover bg-no-repeat">
        <div className="flex flex-col items-center w-[26%] md:w-full gap-[31px] p-8 sm:p-5 bg-white-A700 shadow-xs rounded-[16px]">
          {
            error &&
            <Warning text={error} showImage={false} />
          }
          {/* <Img src="images/logo.png" alt="imageseven_one" className="w-[50%] object-cover" /> */}
          <Text as="h2" className="!text-teal-500 tracking-[0.15px] capitalize text-xl">
            Local Business Lead List
          </Text>
          <div className="flex flex-col items-center gap-[9px]">
            <Text size="md" as="p" className="tracking-[0.18px]">
              Welcome
            </Text>
            <Text as="p" className="!text-gray-600 tracking-[0.15px]">
              Log in to Beanstalk to continue
            </Text>
          </div>
          <CustomInput onChange={setVal} value={inpval.email} name="email"/>
          <PasswordInput showForgot={true} value={inpval.password} name="password" onChange={setVal} />
          <div className="flex flex-col self-stretch items-center gap-[19px]">
            {
              loading ? <Spinner /> :
                <Button shape="round" className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium" onClick={handleSignin} disabled={!cBtn}>
                  Continue
                </Button>

            }
            <Text as="p" className="flex tracking-[0.50px]">
              <span className="text-gray-900">Don’t have an account?&nbsp;</span>
              <a href="sign-up" className="text-teal-600 font-bold underline">
                Sign up
              </a>
            </Text>
          </div>
          <div className="flex sm:flex-col self-stretch justify-center items-center gap-4">
            <div className="sm:self-stretch h-px bg-gray-50 flex-1" />
            <Text as="p" className="self-start !text-gray-600 tracking-[0.50px]">
              OR
            </Text>
            <div className="sm:self-stretch h-px bg-gray-50 flex-1" />
          </div>
          <Button
            shape="round"
            leftIcon={<Img src="images/img_icon.svg" alt="↳ 📍icon " />}
            className="w-full gap-1.5 sm:px-5"
            onClick={handleGoogleLogin}
          >
            Continue with google
          </Button>
        </div>
      </div>
    </>
  );
}
