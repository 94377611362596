import React from "react";
import { Text } from "../../components";
import { default as ModalProvider } from "react-modal";
export default function QueryInfo({ isOpen, handleClose, ...props }) {

    return (
        <ModalProvider {...props} appElement={document.getElementById("root")} isOpen={isOpen}>
            <div className="flex justify-center w-full px-14 py-[122px] md:p-5" onClick={handleClose}>
                <div className="flex flex-col items-start justify-center w-[43%] md:w-full mb-32 gap-4 p-7 sm:p-5 bg-white-A700 rounded-[16px]">
                    <Text size="lg" as="p" className="ml-[3px] md:ml-0 tracking-[0.18px]">
                        Query suggestion
                    </Text>
                    <div className="self-stretch h-px ml-[3px] md:ml-0 bg-gray-50" />
                    <Text as="p" className="ml-[3px] md:ml-0 tracking-[0.15px]">
                        Following are the examples of queries in Nearby address tab:
                        {/* You’re going to process the following queries: */}
                    </Text>
                    <div className="flex flex-col items-start justify-center ml-[3px] gap-[9px] md:ml-0">
                        {["New York","10018","NY"].map((txt, index) => (
                            <Text as="p" className="!text-teal-600 tracking-[0.15px] underline">
                                {txt}
                            </Text>

                        ))}
                    </div>
                </div>
            </div>
        </ModalProvider>
    );
}