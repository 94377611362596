import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img, Warning } from "../../components";

const VerificationLink = () => {
    const [error, setError] = useState('');
    
    return (
      <>    
        <Helmet>
            <title>Local Business Lead List</title>
            <meta name="description" content="Web site created using create-react-app" />
        </Helmet>
        <div className="flex justify-center items-center h-[1080px] w-full md:h-auto px-14 py-[358px] md:p-5 bg-[url(/public/images/img_beanstalk_map_scrapper.png)] bg-cover bg-no-repeat">
            <div className="flex flex-col items-center w-[36%] md:w-full gap-[31px] p-8 sm:p-5 bg-white-A700 shadow-xs rounded-[16px]">
                {
                    error &&
                    <Warning text={error} showImage={false} />
                }
                <Img src="images/logo.png" alt="imageseven_one" className="w-[50%] object-cover" />
                <div className="flex flex-col items-center w-[68%] md:w-full gap-1">
                    <Text as="p" className="!text-gray-600 tracking-[0.15px] text-center leading-6">
                        Sent verification link to your email successfully.&nbsp; 
                        Please confirm your mail inbox.
                    </Text>
                </div>
                <div className="flex flex-col self-stretch items-center gap-[19px]">
                    <Button shape="round" className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium">
                        Resend
                    </Button>
                    <Text as="p" className="flex tracking-[0.50px]">
                        <span className="text-gray-900">Go to sign in page&nbsp;</span>
                        <a href="sign-in" className="text-teal-600 font-bold underline">
                            Sign in
                        </a>
                    </Text>
                </div>

            </div>
        </div>
      </>
    );
}

export default VerificationLink;