import React, { useState } from "react";
import { Button, Heading, Text, Img, Input } from "../../components";
import { default as ModalProvider } from "react-modal";
import { formatTimestamp } from "utils/utils";
import { downlaodResult } from "api/auth";
import * as XLSX from "xlsx";
import { Spinner } from "components/Spinner";
import CustomInput from "components/CustomInput";
export default function Result({ isResult, handleClose, obj, ...props }) {
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState(isResult.name);
  const handleCsvDownload = async () => {
    setLoading(true);
    try {
      const downloadFilename = filename.toLowerCase().endsWith(".csv")
        ? filename
        : `${filename}.csv`;
      const data = await downlaodResult(isResult._id, isResult.taskType);
      let jsonData = [];
      if (isResult.fileUrl.split(".").pop().toLowerCase() === "jsonl") {
        jsonData = data
          .split("\n")
          .map((line) => {
            try {
              return JSON.parse(line);
            } catch (parseError) {
              return null; // Return null for the invalid JSON line
            }
          })
          .filter((item) => item !== null); // Filter out the null values (invalid JSON lines)
      } else jsonData = data;
      jsonData = jsonData.map((obj) => {
        if (obj && obj.hasOwnProperty("id")) {
          delete obj.id; // Delete 'id' property if it exists
        }
        return obj;
      });
      const ws = XLSX.utils.json_to_sheet(jsonData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, downloadFilename);
    } catch (error) {
      console.error("Error downloading task:", error);
    } finally {
      setLoading(false);
    }
  };
  const isOpen = isResult === null ? false : true;
  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      className="flex min-w-[100%] h-[100%] items-center justify-center"
    >
      {isResult && (
        <div className="flex flex-col items-start justify-center gap-4  bg-white-A700 rounded-[16px] p-10 border-2">
          {/* <div className="flex flex-col items-center justify-center w-[61%] md:w-full gap-[31px] p-7 sm:p-5 bg-white-A700 shadow-xs rounded-[16px]"> */}
          <Text
            size="lg"
            as="p"
            className="!text-teal-600 tracking-[0.18px] text-center"
          >
            CSV File Result
          </Text>
          {/* <Warning text="For your convenience, the file is available for download from this location or via the link in your email.
                            If you cannot locate the email, simply download it here."/> */}
          {/* <div className="flex md:flex-col self-stretch justify-center items-start w-full gap-2 p-2 mx-auto md:p-5 border-lime-900 border border-solid bg-red-50_01 max-w-[1036px] rounded-lg">
                        <Img src="images/img_phwarning.svg" alt="phwarning_one" className="h-[24px] w-[24px] md:w-full" />
                        <Text as="p" className="w-[98%] md:w-full !text-lime-900_01 tracking-[0.15px] leading-6">
                            For your convenience, the file is available for download from this location or via the link in your email.
                            If you cannot locate the email, simply download it here.
                        </Text>
                    </div> */}
          <div className="flex flex-col self-stretch w-full gap-2 mx-auto max-w-[1036px]">
            <div className="justify-center gap-2 grid-cols-[repeat(auto-fill,_minmax(482px_,_1fr))] grid">
              <div className="flex justify-between w-full gap-5 p-4 border-gray-50 border border-solid flex-wrap rounded-lg">
                <Text
                  as="p"
                  className="self-start !text-gray-900 tracking-[0.15px]"
                >
                  Order Type
                </Text>
                <Heading
                  as="h1"
                  className="self-start tracking-[0.15px] text-right"
                >
                  {isResult.payload.searchOption}
                </Heading>
              </div>
              <div className="flex justify-between w-full gap-5 p-4 border-gray-50 border border-solid flex-wrap rounded-lg">
                <Text
                  as="p"
                  className="self-start !text-gray-900 tracking-[0.15px]"
                >
                  Task title
                </Text>
                <Heading
                  as="h2"
                  className="self-start tracking-[0.15px] text-right"
                >
                  {isResult.name}
                </Heading>
              </div>
              <div className="flex justify-between w-full gap-5 p-4 border-gray-50 border border-solid flex-wrap rounded-lg">
                <Text
                  as="p"
                  className="self-start !text-gray-900 tracking-[0.15px]"
                >
                  Rows
                </Text>
                <Heading
                  as="h3"
                  className="self-start tracking-[0.15px] text-right"
                >
                  {isResult.count}
                </Heading>
              </div>
              <div className="flex justify-between w-full gap-5 p-4 border-gray-50 border border-solid flex-wrap rounded-lg">
                <Text
                  as="p"
                  className="self-start !text-gray-900 tracking-[0.15px]"
                >
                  Date
                </Text>
                <Heading
                  as="h4"
                  className="self-start tracking-[0.15px] text-right"
                >
                  {formatTimestamp(isResult.timestamp)}
                </Heading>
              </div>
            </div>
            <div className="flex justify-between gap-5 p-3 border-gray-50 border border-solid flex-wrap rounded-lg">
              <Text
                size="lg"
                as="p"
                className="self-start ml-[3px] md:ml-0 !text-gray-900 tracking-[0.18px]"
              >
                Total
              </Text>
              <Heading
                size="md"
                as="h5"
                className="self-start mr-[3px] md:mr-0 !text-teal-600 tracking-[0.18px] text-right"
              >
                $ {isResult.payment.amount}
              </Heading>
            </div>
          </div>
          <div className="self-stretch h-px w-full mx-auto md:p-5 bg-gray-50 max-w-[1036px]" />
          <CustomInput
            label="Filename"
            placeholder="Enter file name here"
            required={false}
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
          />
          <Input
            color="gray_50"
            size="md"
            variant="fill"
            shape="round"
            name="materialsymbols"
            placeholder={`CSV FILE`}
            prefix={
              <Img
                src="images/img_material_symbol.svg"
                alt="material-symbols-light:save-outline"
              />
            }
            suffix={
              loading ? (
                <Spinner />
              ) : (
                <Img
                  src="images/img_mingcutepdffill.svg"
                  alt="mingcute:pdf-fill"
                  onClick={handleCsvDownload}
                />
              )
            }
            className="self-stretch gap-2.5 tracking-[0.15px] font-bold"
          />
          <div className="flex mb-[3px] gap-4">
            <Button
              shape="round"
              className="sm:px-5 tracking-[1.25px] uppercase font-medium min-w-[174px]"
              onClick={handleClose}
            >
              Back home
            </Button>
            {/* <Button
                            variant="gradient"
                            shape="round"
                            color="teal_300_teal_600"
                            className="sm:px-5 tracking-[1.25px] uppercase font-medium min-w-[174px]"
                        >
                            Print
                        </Button> */}
          </div>
        </div>
      )}
    </ModalProvider>
  );
}
