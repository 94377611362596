import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setUser } from '../store/slices/UserSlice';

const Success = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate(); // Using useNavigate for programmatic navigation
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const email = params.get('email');
  const name = params.get('name');
  const userObj = useSelector((state) => state.user.userObj); // Select userObj from Redux state

  useEffect(() => {
    if (token && email) {
      dispatch(setUser({ token, name, email }));
    }
  }, [dispatch, token, name, email]);

  useEffect(() => {
    if (userObj && userObj.token) {
      navigate('/home'); // Redirect to home page
    }
  }, [userObj, navigate]);

  return null;
};

export default Success;
