import React from "react";
import { Text, Input } from "./..";
export default function Suggestions({ message, options = ['Doctor', 'Restaurant', 'Atm', 'Pharmacy'], ...props }) {
  return (
    <div {...props}>
      {/* <Input shape="round" name="search" placeholder={`Search Query`} className="sm:pr-5" /> */}
      <div className="flex">
        <Text size="xs" as="p" className="flex !text-gray-900_99 tracking-[0.40px] mt-2">
          {options.map((text, i) => {
            return (
              <>
                <span className="text-gray-900_99">{i == 0 ? 'Try' : ''}&nbsp;&nbsp;&nbsp;</span>
                <a href="#" className="text-teal-600 underline">
                  {text}
                </a>
              </>
            )
          })}
        </Text>
      </div>
    </div>
  );
}