export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_ID;
export const MIN_SEARCH = 1000;

export const NEARBY_AREA = "Nearby Areas";
export const SELECTEE_STATES = "Selected States";
export const WHOLE_USA = "Whole USA";

export const PRIVILEGEDEMAILS = [
  "umerua27@gmail.com",
  "dean.fiacco@gmail.com",
  "umer@beanstalkconsulting.co",
  "william.jin.719@gmail.com",
];
