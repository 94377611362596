import React, { useState } from "react";
import { Button, Text } from "../../components";
import { default as ModalProvider } from "react-modal";
import { Spinner } from "components/Spinner";
import { deleteTask } from "api/auth";
import { toast } from "react-toastify";

export default function DeleteTask({
  isOpen,
  handleClose,
  token,
  payload,
  handleRefresh,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await deleteTask(token, payload.id, payload.category);
      toast.success("Deleted Successfully");
      handleRefresh()
      handleClose()
      // Introduce a delay before reloading the page
      // setTimeout(() => {
      // }, 400); // 1 second delay
    } catch (error) {
      console.error("Error deleting task:", error);
      toast.error("Error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isOpen}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Are you sure you want to delete this task?
        </Text>
        <div className="w-full h-px bg-gray-50 mb-4" />
        <Text as="p" className="tracking-[0.15px] mb-4">
          <strong>{payload.tName}</strong> - <strong>{payload.category}</strong>
        </Text>
        <div className="flex justify-between w-full gap-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button
                shape="round"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                // variant="gradient"
                shape="round"
                color="red_50_01"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                style={{ backgroundColor: "#F44336", color: "white" }}
                onClick={handleSubmit}
              >
                Delete
              </Button>
            </>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}
