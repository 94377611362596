import { Button, Img } from "components";
import CustomInput from "components/CustomInput";
import React, { useState, useEffect, useRef } from "react";

const MultipleSelectInput = ({
  options,
  label,
  placeholder,
  selected,
  setSelected,
  customInput = false,
  multiselect = true,
  required=true
}) => {
  const catMenu = useRef(null);
  const [inputVal, setInputVal] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false); // State to track suggestion box visibility

  const handleClick = () => {
    setIsOpen(!isOpen); // Toggle suggestion box visibility on click
  };

  const handleChange = (event) => {
    setIsOpen(true);
    const val = event.target.value;
    setInputVal(val);
    if (val) {
      const filterOptions = options.filter((option) =>
        option.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredOptions(filterOptions);
    } else setFilteredOptions(options);
  };

  const handleSelected = (option) => {
    if (multiselect) {
      const newSelected = [...selected, option];
      setSelected(newSelected);
    } else {
      setSelected([option]);
      setIsOpen(false);
    }
    setFilteredOptions(options)
    setInputVal("");
  };

  const handleRemove = (option) => {
    setSelected(selected.filter((item) => item !== option));
  };
  const closeOpenMenus = (e) => {
    if (isOpen && !catMenu.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };
  // FIXME optimzie
  document.addEventListener("mousedown", closeOpenMenus);
  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      const input = event.target.value;
      if(input) handleSelected(input);
    }
  };
  return (
    <div className="mt-2 relative" ref={catMenu}>
      <CustomInput
        onClick={handleClick}
        onChange={handleChange}
        placeholder={placeholder}
        label={label}
        onKeyDown={customInput ? handleKeyDown : undefined}
        value={inputVal}
        suffix={
          <Img
            src="images/img_arrowdown.svg"
            alt="close"
            className="h-[18px] w-[18px]"
            onClick={handleClick}
          />
        }
        required={required}
      />

      {isOpen && filteredOptions.length > 0 && (
        <div
          className="absolute top-full left-0 w-full p-4 rounded-md shadow-md z-50"
          style={{ backgroundColor: "#f9fafb" }}
        >
          <ul className="overflow-y-auto max-h-60">
            {filteredOptions.map((option) => {
              const isSelected = selected.includes(option);
              return (
                <li
                  key={option}
                  className={`${
                    isSelected ? "bg-gray-300" : "hover:bg-gray-300"
                  } px-3 py-2 flex items-center justify-between rounded`}
                  onClick={
                    isSelected ? undefined : () => handleSelected(option)
                  }
                >
                  {option}
                  {isSelected && (
                    <Img
                      src="images/img_close_teal_300.svg"
                      alt="close"
                      onClick={() => handleRemove(option)}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <div className="p-2 border-gray-50 border border-solid rounded-[16px]">
        <div className="flex flex-col items-start gap-2">
          <div className="flex sm:flex-col self-stretch gap-2 overflow-x-auto">
            {selected.map((name, i) => (
              <Button
                size="xs"
                variant="fill"
                rightIcon={
                  <Img
                    src="images/img_close_teal_300.svg"
                    alt="close"
                    onClick={() => handleRemove(name)}
                  />
                }
                className="sm:self-stretch w-full gap-[11px] !text-black-900 tracking-[0.40px] flex-1 rounded-[16px] min-w-fit"
              >
                {name}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleSelectInput;
